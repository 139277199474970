.signIn {
  display: flex;
  flex-direction: column;
  width: 30rem;
  max-width: 92vw;
  height: fit-content;
  background-color: var(--bg-color);
  padding: 2em;
  padding-bottom: 2.6em;
  border: 1.8px solid var(--primary-color);
  border-radius: 5px;
  margin-top: 2.5em;
}

.header {
  align-self: center;
  font-size: 2.2em;
  font-weight: 600;
  color: white;
}
.message {
  cursor: pointer;
  color: white;
  align-self: center;
  margin-top: 0.8em;
}

.toggle {
  color: var(--primary-color);
  font-weight: 500;
}

.signIn > div {
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
}

.signIn > div > label {
  font-family: inherit;
  font-weight: 600;
  color: var(--primary-color);
}

.signIn > div > input {
  font-family: inherit;
  outline: none;
  border: 0;
  color: white;
  background-color: var(--secondary-bg-color);
  font-size: 1.1rem;
  padding: 0.65em;
  border-radius: 5px;
}

.signIn > button {
  cursor: pointer;
  font-family: inherit;
  margin-top: 2em;
  font-weight: 500;
  outline: none;
  border: 0;
  color: white;
  background-color: var(--secondary-color);
  font-size: 1rem;
  padding: 0.65em;
  border-radius: 5px;
}
