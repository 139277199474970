:root {
  --primary-color: #20c997;
  --secondary-color: #087f5b;
  --bg-color: #222222;
  --secondary-bg-color: #111;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  overflow-y: hidden;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}

input::-ms-reveal {
  filter: invert(100%);
}
